<template>
  <div>
    <h2>Review User Session Activity</h2>
    <div id="component-breadcrumbs">
      <b-breadcrumb
        class="breadcrumb-slash"
        :items="breadcrumbItems"
      />
    </div>
    <hr>
    <div class="row">
      <div class="col-6">
        <div class="search-wrapper">
          <h3>Select User by User ID</h3>
          <b-form-group
            label=""
            label-for="userToLose"
          >
            <v-select
              id="userToLose"
              v-model="ReviewingUser"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="userOptions"
              placeholder=""
              name="userToLose"
              :disabled="readonly"
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="authSessions"
        :search-options="{
          enabled: false
        }"
        :pagination-options="{
          enabled: false,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: DateCreated -->
          <span
            v-if="props.column.field === 'createdAt'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.createdAt | dateFormat }}</span>
          </span>

          <!-- Column: Valid Until -->
          <span
            v-else-if="props.column.field === 'validUntil'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.validUntil | dateFormat }}</span>
          </span>

          <!-- Column: Invalid Dated -->
          <span
            v-else-if="props.column.field === 'invalidatedAt'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.invalidatedAt | dateFormat }}</span>
          </span>

          <!-- Column: Activity Range -->
          <span
            v-else-if="props.column.field === 'refreshCount'"
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row.refreshCount | activityRange }}</span>
          </span>

          <!-- Column: IPAdress/Browser -->
          <span
            v-else-if="props.column.field === 'sessionInfoJson'"
          >
            <div>
              <mark>HostIP: </mark>
              {{ sessionInfo(props.row.sessionInfoJson, "HostIP") }}
            </div>
            <div>
              <mark>Source: </mark>
              {{ sessionInfo(props.row.sessionInfoJson, "Source") }}
            </div>
            <div>
              <mark>UserAgent: </mark>
              {{ sessionInfo(props.row.sessionInfoJson, "UserAgent") }}
            </div>
          </span>

          <!-- Column: All -->
          <span
            v-else
            class="text-nowrap"
          >
            <span class="text-nowrap">{{ props.row[props.column.field] }}</span>
          </span>

        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {BBreadcrumb, BFormGroup} from "bootstrap-vue";
import APIService from "@core/utils/APIService";
import "vue-good-table/dist/vue-good-table.css";
import {VueGoodTable} from "vue-good-table";

const apiService = new APIService();

export default {
  components: {
    VueGoodTable,
    BFormGroup,
    BBreadcrumb,
    vSelect,
  },
  filters: {
    dateFormat(val) {
      if (!val) return null;
      const date = new Date(val);
      const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
      const timeOptions = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const Time = date.toLocaleTimeString("en-US", timeOptions);
      if (date) return date.toLocaleDateString("en-US", options) + " " + Time;
    },
    activityRange(val) {
      const fromCount = val * 15;
      const toCount = fromCount + 15;
      return fromCount + " - " + toCount + " min"
    },
  },
  data() {
    return {
      ReviewingUser: null,
      readonly: false,
      userOptions: [],
      authSessions: [],
      breadcrumbItems: [
        {
          text: "Admin",
          href: '#'
        },
        {
          text: "Utility",
          href: '/admin/utility'
        },
        {
          text: "Review User Session Activity",
          active: true,
        },
      ],
      columns: [
        {
          label: "Created Date",
          field: "createdAt",
        },
        {
          label: "Valid Until",
          field: "validUntil",
        },
        {
          label: "IPAdress/Browser",
          field: "sessionInfoJson",
          width: "40rem"
        },
        {
          label: "Activity Range",
          field: "refreshCount",
        },
        {
          label: "Logout Date",
          field: "invalidatedAt",
        },
        {
          label: "Logout Reason",
          field: "invalidationReason",
        },
      ],
    }
  },
  watch: {
    ReviewingUser(val, oldVal) {
      if (!val) this.authSessions = []
      if (val && val !== oldVal) {
        this.authSessions = []
        this.loadUserSessionDetail(val.value)
      }
    },
  },
  mounted() {
    this.loadUsers()
  },
  methods: {
    loadUsers() {
      apiService.get('users/user')
          .then(res => {
            this.userOptions = res.data.Users.map(i => ({
              title: i.UserAccountID + " - " + i.LastName + ", " + i.FirstName + " - " + i.EMail,
              value: i.UserAccountID,
              name: i.LastName + ", " + i.FirstName
            }))
          })
          .catch(error => error)
    },

    loadUserSessionDetail(userID) {
      apiService.get('auth-session/' + userID)
          .then(res => {
            if (res && res.data) {
              this.authSessions = res.data.authSessions
            }
          })
    },

    sessionInfo(val, key) {
      const result = JSON.parse(val)
      switch (key) {
        case "HostIP":
          return result.HostIP;
        case "Source":
          return result.Source;
        case "UserAgent":
          return result.UserAgent;
      }
    }
  }
}
</script>

<style scoped>

</style>